.c-form-field {
    margin-bottom: $spacing-unit;
}

.c-form-captcha {
    display: none;
    color: red;
    margin-bottom: $spacing-unit;
}

.c-form-label {
    display: inline-block;
    color: var(--grey-darkest-color);
    margin-bottom: $spacing-unit-tiny;
    font-weight: 600;

    &[for] {
        cursor: pointer;
    }
}

.c-form-text,
.c-form-email,
.c-form-select,
.c-form-textarea {
    display: block;
    appearance: none;
    width: 100%;
    border: 2px solid var(--grey-lightest-color);
    font-size: 1.6rem;
    line-height: 1.5;
    border-radius: 4px;
    padding: 6px $spacing-unit-tiny;

    &:focus {
        outline: 0;
        border-color: var(--grey-light-color);
    }

    &:disabled,
    &[aria-disabled=true] {
        opacity: 0.5;
    }

    option {
        font-weight: normal;
    }
}

.c-form-select {
    background: var(--white-color) url('/img/global/select-chevron-dark-icon.svg') no-repeat right $spacing-unit-small top 50%;

    &:focus {
        max-width: 100%;
        box-shadow: none;
    }

    &::-ms-expand {
        display: none;
    }
}

.c-form-textarea {
    height: 160px;
    min-height: 100px;
    min-width: 100%;
    max-width: 100%;
}

.c-form-markup {
    font-size: 1.6rem;
    color: var(--grey-color);

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-form-submit {
    @extend %c-button;
    @extend %c-button--brand;
}

.grecaptcha-badge {
    display: none;
}